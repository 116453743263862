// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-name-js": () => import("./../src/pages/blog/name.js" /* webpackChunkName: "component---src-pages-blog-name-js" */),
  "component---src-pages-blog-post-js": () => import("./../src/pages/blog/[post].js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-templates-advanced-js": () => import("./../src/templates/advanced.js" /* webpackChunkName: "component---src-templates-advanced-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-dosing-reminder-js": () => import("./../src/templates/dosing-reminder.js" /* webpackChunkName: "component---src-templates-dosing-reminder-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-newform-js": () => import("./../src/templates/newform.js" /* webpackChunkName: "component---src-templates-newform-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfolio-js": () => import("./../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-prescription-reminder-js": () => import("./../src/templates/prescription-reminder.js" /* webpackChunkName: "component---src-templates-prescription-reminder-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-regform-js": () => import("./../src/templates/regform.js" /* webpackChunkName: "component---src-templates-regform-js" */)
}

